.componentContainer {
  padding: var(--size-80) 0 var(--size-64);
  color: var(--theme-text-color);
  background-color: var(--theme-background-color);
  overflow: hidden;

  @media (--viewport-md) {
    padding: var(--size-150) 0 var(--size-40);
  }
}

.component {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-32);

  & > .tagline {
    max-width: 800px;
  }
}

.tagline {
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-300);
  text-align: center;

  @media (--viewport-md) {
    padding: 0 var(--size-48);
  }
}

.componentTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--size-24);
  padding-bottom: var(--size-12);

  @media (--viewport-md) {
    padding-bottom: var(--size-32);
  }

  & > .imageLayout {
    max-width: var(--size-150);
    margin-top: calc(-1 * var(--size-24));
    margin-right: calc(-1 * var(--size-32));

    @media (--viewport-sm) {
      margin-right: calc(-1 * var(--size-48));
    }

    @media (--viewport-md) {
      margin-top: calc(-1 * var(--size-64));
      margin-right: calc(-1 * var(--size-64));
    }
  }
}

.title {
  font-family: var(--font-family-base);
  font-size: var(--font-size-50-150);
  font-weight: var(--font-weight-700);
  line-height: var(--line-height-normal);
  letter-spacing: var(--letter-spacing-heading);
}

.italic {
  text-transform: lowercase;
  font-family: var(--font-family-italic);
}

.image {
  @media (--viewport-md) {
    transform: translateX(var(--size-64));
  }
}

.componentMenu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--size-12) var(--size-32);

  @media (--viewport-md) {
    gap: var(--size-24) var(--size-64);
  }
}

.componentLink {
  --label-underline-opacity: 0;
  --label-underline-color: var(--theme-text-color);

  font-weight: var(--font-weight-300);

  &:hover {
    --label-underline-opacity: 1;
  }
}
