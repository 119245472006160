.component {
  z-index: 0;
  position: relative;
  display: none;
  pointer-events: auto;
  color: var(--menu-text-color);
  transition: color var(--duration-250) var(--ease);

  @media (--viewport-lg) {
    display: block;
  }

  & > .backgroundLayout {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 0;
    top: 0;
  }
}

.componentBackground {
  --submenu-height: 0;

  background-color: var(--theme-menu-background-color);
  transition: height var(--duration-400) var(--ease);
}

.componentMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-16) 0;
  user-select: none;

  & > .menuItemLayout {
    height: 100%;
  }
}

.logoContainer {
  color: var(--color-red-500);
}

.componentMenuItemLink,
.submenuButtonLabel {
  display: flex;
  align-items: center;
  font-family: var(--font-family-condensed);
  font-size: var(--size-20);
  text-transform: uppercase;
  text-decoration: none;
}

.componentMenuItemLink {
  & > .linkLayout {
    height: 100%;
  }
}

.submenuButtonLabel {
  --label-underline-opacity: 0;

  &:hover,
  &.isCurrentLocation {
    --label-underline-opacity: 1;
  }
}

.menuItemSubmenu {
  & > .submenuButton {
    height: 100%;
  }
}

.submenuButton {
  display: flex;
  align-items: center;
  gap: var(--size-12);

  & > .iconContainer {
    width: var(--size-12);
  }
}

.iconContainer {
  transition: transform var(--duration-400) var(--ease-out-expo);

  &.isActive {
    transform: rotateX(180deg);
  }

  & > .iconLayout {
    width: 100%;
  }
}

.submenuContainer {
  pointer-events: none;

  &.isActive {
    pointer-events: auto;
  }
}

.componentSubmenu {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
  padding: var(--size-32) 0 var(--size-80);
  color: var(--theme-menu-color);
}

.componentSubmenuItemLink {
  --index: 0;

  opacity: 0;
  font-family: var(--font-family-base);
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-text);
  transition-property: opacity;
  transition-duration: var(--duration-100);
  transition-timing-function: var(--ease-out-quad);
  transition-delay: 0s;

  &.isActive {
    opacity: 1;
    transition-duration: var(--duration-250);
    transition-delay: calc(0.1s + 0.05s * var(--index));
  }

  & > .linkLayout {
    width: max-content;
  }
}
