.component {
  position: sticky;
  display: flex;

  & > .menuLayout {
    width: 100%;
  }
}

.componentMenu {
  /* override default floatingUI styling */
  position: relative !important;
  overflow: visible !important;
  pointer-events: none;
  background: transparent;
  transition: background var(--duration-600) var(--ease);

  &.isActiveMobileMenu {
    pointer-events: auto;
    background: var(--color-black--30);
  }
}

.menuContainer {
  --menu-text-color: var(--color-white);
  --label-underline-color: var(--menu-text-color);

  background-color: transparent;
  transform: translateY(-100%);
  transition:
    background-color var(--duration-600) var(--ease),
    transform var(--duration-400) var(--ease-out-quad),
    box-shadow var(--duration-400) var(--ease);

  &.blackMenu {
    --menu-text-color: var(--color-black);
  }

  &.isVisible {
    transform: translateY(0);
  }

  &.isScrolledDown {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.12);
  }

  &.isSolid {
    --menu-text-color: var(--theme-header-color);

    background-color: var(--theme-background-color);
  }

  &.isDesktopSubmenuActive,
  &.isActiveMobileMenu {
    --menu-text-color: var(--theme-menu-color);
  }

  & > .menuMobileLayout,
  & > .menuDesktopLayout {
    width: 100%;
  }
}
