.componentBase,
.componentLinkBase {
  --opacity-hover-label: 0;

  position: relative;
  display: inline-flex;
  align-items: center;
  gap: var(--size-16);
  border-radius: var(--radius-15);
  font-family: var(--font-family-base);
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-paragraph);

  &:disabled {
    cursor: not-allowed;
  }

  & > .labelLayout {
    height: max-content;
  }

  & > .iconLayout {
    height: var(--size-64);
    width: var(--size-64);
  }

  & > .loaderLayout {
    position: absolute;
    width: var(--size-32);
    right: var(--size-20);
  }
}

.componentLinkBase {
  & > .labelLayout {
    width: 100%;
  }

  & > .iconLayout {
    height: var(--size-64);
    width: var(--size-64);
  }
}

.component,
.componentRed,
.componentSubmit,
.componentIcon,
.componentIconReversed,
.componentLink,
.componentLinkRed,
.componentLinkWhite {
  --label-underline-opacity: 0;
  --label-underline-color: var(--theme-button-color);

  justify-content: center;
  flex-wrap: wrap;
  text-align: center;

  &:hover {
    --label-underline-opacity: 1;
  }
}

.component,
.componentRed,
.componentGhost,
.componentSubmit,
.componentLink,
.componentLinkRed,
.componentLinkGhost,
.componentLinkWhite {
  padding: var(--size-16) var(--size-32);
}

.componentRed,
.componentSubmit,
.componentLinkRed {
  --label-underline-color: var(--color-white);

  color: var(--color-white);
  background-color: var(--color-red-500);
  border: 2px solid var(--color-red-500);
}

.componentGhost,
.componentLinkGhost {
  --label-underline-opacity: 0;
  --label-underline-color: var(--theme-text-color);

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  color: var(--theme-button-color);
  background: transparent;
  border: 2px solid var(--theme-button-color);

  &:hover {
    --label-underline-opacity: 1;
  }
}

.componentLinkWhite {
  --label-underline-color: var(--color-black);

  color: var(--color-black);
  background-color: var(--color-white);
  border: 2px solid var(--color-white);
}

.componentLinkArrow,
.componentLinkArrowReversed {
  --label-underline-opacity: 0;
  --label-underline-color: var(--theme-text-color);

  position: relative;
  display: flex;
  align-items: center;
  gap: var(--size-16);
  color: var(--theme-text-color);

  &:hover {
    --label-underline-opacity: 1;
  }
}

.componentIconReversed,
.componentLinkArrowReversed {
  flex-direction: row-reverse;
}

.componentIconBox {
  aspect-ratio: 1;
  display: grid;
  place-items: center;
  color: var(--theme-button-icon-color);
  background-color: var(--theme-button-color);
  border-radius: var(--radius-15);

  & > .iconLayout {
    height: var(--size-20);
    width: var(--size-20);
  }
}
