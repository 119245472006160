.component {
  --label-underline-opacity: 0;

  display: flex;
  align-items: center;
  color: inherit;

  &:hover,
  &.isCurrentLocation {
    --label-underline-opacity: 1;
  }
}
