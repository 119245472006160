.component {
  position: relative;

  &::before {
    content: attr(data-content);
    position: absolute;
    width: 100%;
    left: 0;
    top: 2px;
    color: transparent;
    text-decoration: underline;
    text-decoration-color: var(--label-underline-color);
    text-decoration-thickness: 2px;
    transition: var(--duration-250) var(--ease);
    transition-property: opacity, color;
    opacity: var(--label-underline-opacity);
  }
}
