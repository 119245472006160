.component {
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, var(--color-white) 94%, transparent) top/8px 8px no-repeat,
    conic-gradient(transparent 30%, var(--color-white));
  mask: radial-gradient(farthest-side, transparent calc(100% - 8px), var(--color-black) 0);
  animation: rotateAnimation 1s infinite linear;
}

@keyframes rotateAnimation {
  100% { transform: rotate(1turn); }
}
