.componentBase {
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-700);
  line-height: var(--line-height-normal);

  &.centered {
    text-align: center;
  }
}

.componentCondensedBase {
  font-family: var(--font-family-condensed);
  line-height: var(--line-height-heading-condensed);
  text-transform: uppercase;
}

.componentXs {
  font-size: var(--font-size-20-25);
}

.componentSm {
  font-size: var(--font-size-24-35);
}

.componentMd {
  font-size: var(--font-size-48-55);
}

.componentLg {
  font-size: var(--font-size-50-100);
  letter-spacing: var(--letter-spacing-heading);
}

.componentCondensedSm {
  font-size: var(--font-size-48-70);
  font-weight: var(--font-weight-400);
  letter-spacing: var(--letter-spacing-heading);
}

.componentCondensedMd {
  font-size: var(--font-size-50-100);
  font-weight: var(--font-weight-400);
  letter-spacing: var(--letter-spacing-heading);
}

.componentCondensedLg {
  font-size: var(--font-size-60-130);
  font-weight: var(--font-weight-400);
  letter-spacing: var(--letter-spacing-heading);
}

.componentCondensedXl {
  font-size: var(--font-size-60-150);
  font-weight: var(--font-weight-400);
  letter-spacing: var(--letter-spacing-heading);
}

.componentCondensedXxl {
  font-size: var(--font-size-80-240);
  font-weight: var(--font-weight-400);
  letter-spacing: var(--letter-spacing-heading-xl);
}
