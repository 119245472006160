:root {
  --duration-100: 0.1s;
  --duration-250: 0.25s;
  --duration-400: 0.4s;
  --duration-600: 0.6s;

  --ease: ease;
  --linear: linear;
  --ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);
  --ease-out-quad: cubic-bezier(0.5, 1, 0.89, 1);
  --ease-in-out-circ: cubic-bezier(0.85, 0, 0.15, 1);
  --ease-out-circ: cubic-bezier(0.57, 0.4, 0.55, 1.17);
}
