.componentBase {
  --container-padding: 0 min(5vw, calc(2.5vw + 24px));

  display: flex;
  justify-content: center;
  padding: var(--container-padding);

  & > .layout {
    width: 100%;
  }
}

.componentSm {
  & > .layout {
    max-width: var(--container-max-width-sm);
  }
}

.componentMd {
  & > .layout {
    max-width: var(--container-max-width-md);
  }
}

.componentLg {
  & > .layout {
    max-width: var(--container-max-width-lg);
  }
}

.componentXl {
  & > .layout {
    max-width: var(--container-max-width-xl);
  }
}
