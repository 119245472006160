@font-face {
  font-family: 'Nexa';
  src:
    url('/fonts/Nexa/Nexa-Book.woff2') format('woff2'),
    url('/fonts/Nexa/Nexa-Book.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nexa';
  src:
    url('/fonts/Nexa/Nexa-Regular.woff2') format('woff2'),
    url('/fonts/Nexa/Nexa-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nexa';
  src:
    url('/fonts/Nexa/Nexa-Bold.woff2') format('woff2'),
    url('/fonts/Nexa/Nexa-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Americane-condensed-medium';
  src:
    url('/fonts/AmericaneCondensed/Americane-Condensed-Web-Medium.woff2') format('woff2'),
    url('/fonts/AmericaneCondensed/Americane-Condensed-Web-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlex-serif-italic';
  src:
    url('/fonts/IBMPlexSerif/IBMPlexSerif-Italic.woff2') format('woff2'),
    url('/fonts/IBMPlexSerif/IBMPlexSerif-Italic.woff') format('woff');
  font-weight: 400;
  /* The font-style must be set to normal to be set correctly on Safari */
  font-style: normal;
  font-display: swap;
}
