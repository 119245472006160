.componentBase {
  padding-top: var(--size-200);

  & > .buttonsLayout {
    margin-top: var(--size-52);

    @media (--viewport-md) {
      margin-top: var(--size-150);
    }
  }
}

.componentContent {
  display: flex;
  gap: var(--size-40);
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: var(--theme-text-color);

  & > .introductionLayout {
    max-width: 750px;
  }
}

.componentButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--size-16);
  padding-bottom: var(--size-150);
}

.subtitle {
  font-family: var(--font-family-italic);
  font-size: var(--size-24);
}
