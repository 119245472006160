.componentContainer {
  padding: var(--size-80) 0 var(--size-64);
  color: var(--theme-text-color);
  background-color: var(--theme-background-color);
  overflow: hidden;

  @media (--viewport-md) {
    padding: var(--size-100) 0 var(--size-52);
  }
}

.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-150);
}

.componentMenuAndTitle {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  gap: var(--size-80) var(--size-64);

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .title {
    max-width: 11ch;

    @media (--viewport-md) {
      margin-left: auto;
    }
  }
}

.title {
  text-transform: uppercase;
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-80-150);
  font-weight: var(--font-weight-700);
  line-height: var(--line-height-heading-condensed);
  letter-spacing: var(--letter-spacing-heading);

  @media (--viewport-md) {
    text-align: right;
  }
}

.italic {
  text-transform: lowercase;
  font-family: var(--font-family-italic);
  font-size: var(--font-size-50-100);
}

.componentFooterMenu {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--size-24);

  @media (--viewport-md) {
    gap: var(--size-32);
  }
}

.componentFooterLink {
  --label-underline-opacity: 0;
  --label-underline-color: var(--theme-text-color);

  font-weight: var(--font-weight-300);

  &:hover {
    --label-underline-opacity: 1;
  }
}

.componentLabels {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-48);
  padding-top: var(--size-52);
  border-top: 1px solid var(--color-white--30);

  & > .labelLogoLayout {
    flex: 1;
    max-width: var(--size-125);
  }
}

.componentLabelLogo {
  position: relative;
  display: inline-block;
  filter: drop-shadow(0 1000px 0 var(--color-peach-500));
  transform: translateY(-1000px);

  & > .imageLayout {
    width: 100%;
    height: auto;
  }
}
