.component {
  opacity: 0;
  pointer-events: none;
  display: inline-flex;
  align-items: center;
  gap: var(--size-24);
  padding: var(--size-12) var(--size-24);
  color: var(--color-white);
  background: var(--color-black);
  outline: none;
  border: 1px dashed var(--color-white--30);
  border-radius: var(--radius-15);
  transform: translateY(calc(-100% + var(--size-12) * -1));
  transition:
    transform var(--duration-400) var(--ease),
    opacity var(--duration-250) var(--linear);

  &:focus-within {
    opacity: 1;
    transform: translateY(0%);
    pointer-events: auto;
  }
}
