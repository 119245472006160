.component {
  z-index: 0;
  position: relative;
  pointer-events: auto;

  @media (--viewport-lg) {
    display: none;
  }

  & > .menuContainer {
    z-index: -1;
    position: fixed;
    width: 100vh;
    max-width: 100%;
    height: 100lvh;
    top: 0;
  }
}

.menuContainer {
  overflow: hidden;

  & > .menuList {
    width: 100%;
    height: 100vh;
    top: 0;
  }
}

.menuList {
  /* override default floatingUI styling */
  position: relative !important;
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  padding: var(--size-150) 0;
  padding-inline: var(--container-padding-size);
  color: var(--color-white);
  background-color: var(--color-black);
  pointer-events: auto;
  overflow-y: auto;
}

.componentMenuBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-16) 0;

  & > .hamburgerLayout {
    width: var(--size-24);
    height: var(--size-24);
  }
}

.logoContainer {
  color: var(--color-red-500);
}

.componentMenuItemLink,
.submenuButtonLabel {
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-40);
  text-transform: uppercase;
  text-decoration: none;
  user-select: none;
}

.submenuButtonLabel {
  &.isCurrentLocation {
    text-decoration: underline;
  }
}

.componentMenuItemSubmenu {
  & > .submenuButton {
    width: 100%;
  }
}

.submenuButton {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > .iconContainer {
    width: var(--size-24);
    flex-shrink: 0;
  }
}

.iconContainer {
  transition: transform var(--duration-400) var(--ease-out-expo);

  &.isActive {
    transform: rotateX(180deg);
  }

  & > .iconLayout {
    width: 100%;
  }
}

.submenuContainer {
  pointer-events: none;
  display: grid;
  grid-template-rows: 0fr;
  padding: 0;
  transition: var(--duration-600) var(--ease-out-expo);
  transition-property: grid-template-rows, padding;
  overflow: hidden;

  &.isActive {
    pointer-events: auto;
    grid-template-rows: 1fr;
    padding: var(--size-20) 0 var(--size-16);
  }

  & > * {
    min-height: 0;
  }
}

.componentSubmenu {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
}

.componentSubmenuItemLink {
  --index: 0;

  opacity: 0;
  font-family: var(--font-family-base);
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-text);
  transition-property: opacity;
  transition-duration: var(--duration-100);
  transition-timing-function: var(--ease-out-quad);
  transition-delay: 0s;

  &.isActive {
    opacity: 1;
    transition-duration: var(--duration-250);
    transition-delay: calc(0.1s + 0.05s * var(--index));
  }
}
