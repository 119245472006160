.component {
  display: flex;
  align-items: center;
}

.hamburger {
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: var(--size-24);
    height: 2px;
    left: 0;
    background-color: var(--menu-text-color);
    transition: var(--duration-400) var(--ease-in-out-circ);
  }

  &::before {
    transform: translateY(-6px);
  }

  &::after {
    transform: translateY(6px);
  }

  &.isOpen {
    transition: var(--duration-250) var(--ease-in-out-circ);

    &::before {
      background-color: var(--color-white);
      transform: translateY(0);
      rotate: -45deg;
    }

    &::after {
      background-color: var(--color-white);
      transform: translateY(0);
      rotate: 45deg;
    }
  }
}
